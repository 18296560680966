import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Carousel from "./Carousel";
import { graphql, StaticQuery } from "gatsby";
import { CarouselItem } from "./CarouselItem";
import { useTranslation } from "react-i18next";
import { Motionize } from "./Motion";
import { RiseLoader } from "react-spinners";

const LearnMore = styled(Motionize("a"))`
  grid-area: more;
  font-size: 1rem;
  line-height: 2rem;
  margin-bottom: 2em;
  display: block;
  color: #365b6d;
  cursor: pointer;
  text-decoration: none;
  &:hover {
    color: #637f8d;
  }
`;

const MaxWidthContainer = styled.div`
  display: grid;
  position: relative;
  margin: 0 auto;
  grid-template-rows: 24px auto minmax(100px, auto) 24px 48px;
  grid-template-columns: minmax(24px, auto) minmax(300px, 1300px) minmax(
      24px,
      auto
    );
  grid-template-areas:
    ". . ."
    ". heading ."
    ". carousel ."
    ". more ."
    ". . .";
`;

const Heading = styled(Motionize("h1"))`
  grid-area: heading;
  margin: 24px 0;
`;
const LoadingWrapper = styled.div`
  grid-area: carousel;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ReviewsTemplate = () => {
  const [data, setData] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      const apiUrl =
        "https://google-maps-scraper-2024-07.vercel.app/api/data";
      try {
        const apiResponse = await fetch(apiUrl);

        if (!apiResponse.ok) {
          throw new Error(
            `Failed to fetch data from ${apiUrl}: ${apiResponse.statusText}`
          );
        }

        const data = await apiResponse.json();
        console.log("Data", data.reviews);
        setData(data.reviews);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const { t } = useTranslation();

  return (
    <MaxWidthContainer>
      <Heading>{t("reviews")}</Heading>
      {data ? (
        <Carousel width="360px">
          {data.map(
            ({
              author,
              review_text,
              review_id_hash,
              rating,
              published_at,
            }) => {
              return (
                <CarouselItem
                  key={review_id_hash}
                  author={author}
                  comments={review_text}
                  rating={rating}
                  time={published_at}
                />
              );
            }
          )}
        </Carousel>
      ) : (
        <LoadingWrapper>
          <RiseLoader />
        </LoadingWrapper>
      )}
      <LearnMore href="https://g.co/kgs/vJsrsLt">
        {t("see_more_reviews")} &#8250;
      </LearnMore>
    </MaxWidthContainer>
  );
};

export default function Review() {
  return (
    <StaticQuery
      query={graphql`
        query ReviewsQuery {
          allMarkdownRemark(
            filter: { frontmatter: { templateKey: { eq: "testimonials" } } }
          ) {
            edges {
              node {
                id
                frontmatter {
                  testimonials {
                    text
                    author_name
                  }
                }
              }
            }
          }
        }
      `}
      render={(data) => <ReviewsTemplate data={data} />}
    />
  );
}
